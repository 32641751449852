import React, { useEffect, useState } from "react";
import queryString from "query-string";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import CloudUpoadIcon from "@material-ui/icons/CloudUpload";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import axios from "axios";
import { apiUrl } from "../config";

/**
 * Download page component
 */

const useStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing.unit
  },
  input: {
    display: "none"
  },
  icon: {
    marginRight: theme.spacing(1)
  },
  root: {
    paddingTop: theme.spacing(8)
  },
  button: {
    marginTop: theme.spacing(4)
  }
}));

export default function Download({ location }) {
  const classes = useStyles();
  const params = queryString.parse(location.search);
  const [file, setFile] = useState({ fileId: params.id });

  useEffect(() => {
    //send file info request to back end with params id
    axios
      .get(apiUrl + "/file/info", {
        params: {
          id: params.id
        }
      })
      .then(response => {
        setFile(response.data);
      })
      .catch(function(error) {
        console.log(error);
      });
    return () => {};
  }, [params.id]);

  const downloadFile = () => {
    axios
      .get(apiUrl + "/file/download", {
        params: {
          id: params.id
        }
      })
      .then(response => {})
      .catch(function(error) {});
  };

  return (
    <div>
      <Grid
        container
        direction="column"
        justify="center"
        alignItems="center"
        className={classes.root}
      >
        <Grid item>
          <Typography variant="body1" align="center">
            <b>File:</b> {file && file.name}
          </Typography>
          <Typography variant="body1" align="center">
            <b>Size:</b>{" "}
            {file && parseFloat(file.size / 1024 / 1024).toFixed(2)} MB
          </Typography>
          <Typography variant="body1" align="center">
            <b>Created:</b>{" "}
            {file && new Date(file.created).toLocaleString("en-US")}
          </Typography>
          <Typography variant="body1" align="center">
            <b>Description</b> {file && file.description}
          </Typography>
        </Grid>

        <Grid item>
          <Button
            variant="contained"
            component="a"
            href={
              "https://gcucapstone.s3-us-west-1.amazonaws.com/" +
              file.fileId +
              "/" +
              file.name
            }
            download
            //target="_blank"
            style={{ textDecoration: "none" }}
            className={classes.button}
            onClick={downloadFile}
          >
            Download
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}
