import React, { Component, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography, Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Axios from "axios";
import { apiUrl } from "../config";
import useAuth from "../hooks/useAuth";
import DeleteFile from "./DeleteFile";
import DeleteIcon from "@material-ui/icons/Delete";
import CopyIcon from "@material-ui/icons/FileCopy";
import EditFile from "./EditFile";

/**
 * File dashboard component
 */

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2),
    paddingTop: theme.spacing(4)
  },

  fileImage: {
    height: "300px",
    paddingBottom: theme.spacing(2)
  },

  tableRoot: {
    width: "100%",
    marginTop: theme.spacing(3),
    overflowX: "auto"
  },
  table: {
    minWidth: 650
  },
  container: {
    //maxWidth: "900px"
  }
}));

export default function Files() {
  const classes = useStyles();

  const [files, setFiles] = useState([]);
  const { authState } = useAuth();

  const [selectedFile, setSelectedFile] = useState({});
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);

  const handleDeleteOpen = file => e => {
    setSelectedFile(file);
    setDeleteOpen(true);
  };

  const handleEditOpen = file => e => {
    setSelectedFile(file);
    setEditOpen(true);
  };

  useEffect(() => {
    if (authState.authToken) {
      Axios.get(apiUrl + "/file/getUserFiles", {
        headers: {
          Authorization: "Bearer " + authState.authToken
        }
      })
        .then(response => {
          setFiles(response.data);
        })
        .catch(function(error) {
          console.log(error);
        });
    }
  }, [authState.authToken, deleteOpen]);

  const copyLink = fileId => () => {
    navigator.clipboard.writeText(
      window.location.origin + "/download?id=" + fileId
    );
  };

  return (
    <Box
      className={classes.root}
      display="flex"
      flexDirection="column"
      alignItems="center"
    >
      <div className={classes.container}>
        <Typography variant="h6">My Files ({files.length})</Typography>
        <Paper className={classes.tableRoot}>
          <Table className={classes.table} size="small">
            <TableHead>
              <TableRow style={{ backgroundColor: "#212121" }}>
                <TableCell align="center">Name</TableCell>
                <TableCell align="center">Type</TableCell>
                <TableCell align="center">Size</TableCell>
                <TableCell align="center" style={{ whiteSpace: "nowrap" }}>
                  Downloads (
                  {files.length > 0 &&
                    files
                      .map(f => f.downloads)
                      .reduce((prev, curr) => prev + curr)}
                  )
                </TableCell>
                <TableCell align="center">Created On</TableCell>
                <TableCell align="center"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {files
                .sort((a, b) => {
                  return b.downloads - a.downloads;
                })
                .map(file => (
                  <TableRow hover key={file.fileId}>
                    <TableCell align="center" component="th" scope="row">
                      {file.name}
                    </TableCell>
                    <TableCell align="center">{file.type}</TableCell>
                    <TableCell align="center" style={{ whiteSpace: "nowrap" }}>
                      {parseFloat(file.size / 1024 / 1024).toFixed(2) + " MB"}
                    </TableCell>
                    <TableCell align="center">{file.downloads}</TableCell>
                    <TableCell align="center">
                      {new Date(file.created).toLocaleDateString()}
                    </TableCell>
                    <TableCell align="center">
                      <Box display="flex" flexDirection="row">
                        <Button
                          color="primary"
                          variant="contained"
                          size="small"
                          onClick={handleEditOpen(file)}
                        >
                          <DeleteIcon /> Edit
                        </Button>
                        <Button
                          color="primary"
                          variant="contained"
                          size="small"
                          style={{ marginLeft: 4, whiteSpace: "nowrap" }}
                          onClick={handleDeleteOpen(file)}
                        >
                          <DeleteIcon /> Delete
                        </Button>
                        <Button
                          color="primary"
                          variant="contained"
                          size="small"
                          style={{ marginLeft: 4, whiteSpace: "nowrap" }}
                          onClick={copyLink(file.encodedId)}
                        >
                          <CopyIcon /> Copy
                        </Button>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </Paper>
      </div>

      <DeleteFile
        open={deleteOpen}
        handleClose={() => setDeleteOpen(false)}
        file={selectedFile}
      />

      <EditFile
        open={editOpen}
        handleClose={() => setEditOpen(false)}
        file={selectedFile}
      />
    </Box>
  );
}
