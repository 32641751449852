import React, { useEffect } from "react";
import { CssBaseline } from "@material-ui/core";
import { Provider } from "react-redux";
import store from "./store/configureStore";
import Theme from "./Theme";
import Template from "./containers/Template";

/**
 * Root component
 */

export default function App() {
  return (
    <Provider store={store}>
      <Theme>
        <CssBaseline />
        <Template />
      </Theme>
    </Provider>
  );
}
