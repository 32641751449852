import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  DialogContentText,
  Typography,
  Avatar
} from "@material-ui/core";
import { Formik } from "formik";
import * as Yup from "yup";
import Axios from "axios";
import CheckIcon from "@material-ui/icons/Check";
import { apiUrl } from "../config";

/**
 * Register dialog component
 */

const useStyles = makeStyles(theme => ({
  root: {}
}));

export default function Register({ open, toggle, login }) {
  const classes = useStyles();
  const [success, setSuccess] = useState(false);
  const [serverErrors, setServerErrors] = useState([]);

  useEffect(() => {
    if (open) {
      setSuccess(false);
      setServerErrors([]);
    }
  }, [open]);

  return (
    <div className={classes.root}>
      <Dialog
        onClose={() => toggle(false)}
        aria-labelledby="simple-dialog-title"
        open={open}
        fullWidth
        PaperProps={{ style: { maxWidth: 400 } }}
      >
        {!success && (
          <>
            <DialogTitle id="simple-dialog-title">Sign up</DialogTitle>

            <Formik
              initialValues={{
                email: "",
                password: "",
                confirmPassword: ""
              }}
              onSubmit={(values, { setSubmitting }) => {
                setServerErrors([]);

                Axios.post(apiUrl + "/user/register", values)
                  .then(response => {
                    setSubmitting(false);
                    setSuccess(true);
                  })
                  .catch(error => {
                    setSubmitting(false);
                    setServerErrors(error.response.data.errors);
                  });
              }}
              validationSchema={Yup.object().shape({
                email: Yup.string()
                  .email()
                  .required(),
                password: Yup.string().required(),
                confirmPassword: Yup.string().required()
              })}
            >
              {props => {
                const {
                  values,
                  touched,
                  errors,
                  dirty,
                  isSubmitting,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  handleReset
                } = props;
                return (
                  <form onSubmit={handleSubmit}>
                    <DialogContent>
                      <DialogContentText color="error">
                        {serverErrors.map((error, index) => (
                          <Typography key={index} component="span">
                            {error}
                          </Typography>
                        ))}
                      </DialogContentText>
                      <TextField
                        autoFocus
                        margin="dense"
                        id="email"
                        label="Email Address *"
                        type="email"
                        fullWidth
                        value={values.email}
                        onChange={handleChange}
                        error={!!(touched.email && errors.email)}
                        helperText={touched.email && errors.email}
                      />
                      <TextField
                        margin="dense"
                        id="password"
                        label="Password *"
                        type="password"
                        fullWidth
                        value={values.password}
                        onChange={handleChange}
                        error={!!(touched.password && errors.password)}
                        helperText={touched.password && errors.password}
                      />
                      <TextField
                        margin="dense"
                        id="confirmPassword"
                        label="Confirm Password *"
                        type="password"
                        fullWidth
                        value={values.confirmPassword}
                        onChange={handleChange}
                        error={
                          !!(touched.confirmPassword && errors.confirmPassword)
                        }
                        helperText={
                          touched.confirmPassword && errors.confirmPassword
                        }
                      />
                    </DialogContent>

                    <DialogActions>
                      <Button onClick={() => toggle(false)}>Cancel</Button>
                      <Button
                        type="submit"
                        disabled={isSubmitting}
                        variant="outlined"
                      >
                        Submit
                      </Button>
                    </DialogActions>
                  </form>
                );
              }}
            </Formik>
          </>
        )}
        {success && (
          <>
            <DialogTitle align="center">
              Successfully Created Account
            </DialogTitle>
            <DialogContent align="center">
              <Avatar style={{ color: "white", backgroundColor: "#00cc00" }}>
                <CheckIcon fontSize="large" />
              </Avatar>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => toggle(false)}>Close</Button>
              <Button onClick={() => login()} variant="outlined">
                Login
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    </div>
  );
}
