import React, { Component } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography, Button } from "@material-ui/core";
import { Link } from "react-router-dom";

/**
 * Home page component
 */

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2),
    paddingTop: theme.spacing(6)
  },

  fileImage: {
    height: "300px",
    paddingBottom: theme.spacing(2)
  }
}));

export default function Home() {
  const classes = useStyles();

  return (
    <Box
      className={classes.root}
      display="flex"
      flexDirection="column"
      alignItems="center"
    >
      <img
        className={classes.fileImage}
        src="https://42f2671d685f51e10fc6-b9fcecea3e50b3b59bdc28dead054ebc.ssl.cf5.rackcdn.com/illustrations/collecting_fjjl.svg"
      />
      <Typography variant="h3" gutterBottom>
        FileShare.io
      </Typography>
      <Typography variant="h5" gutterBottom>
        Free. Easy. Fast.
      </Typography>
      <Button
        color="primary"
        variant="contained"
        size="large"
        component={Link}
        to="/upload"
      >
        Get started Now
      </Button>
    </Box>
  );
}
