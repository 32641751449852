import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "../components/Home";
import Upload from "../components/Upload";
import Download from "../components/Download";
import { makeStyles } from "@material-ui/core/styles";
import Files from "../components/Files";

/**
 * React Router Routes
 */

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(8)
  }
}));

export default function Routes({ children }) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Route exact path="/" component={Home} />
      <Route exact path="/upload" component={Upload} />
      <Route exact path="/download" component={Download} />
      <Route exact path="/files" component={Files} />
    </div>
  );
}
