/**
 * Redux constants for authentication
 */

export const constants = {
  SET_AUTH: "SET_AUTH",
  REMOVE_AUTH: "REMOVE_AUTH",

  LOCAL_STORAGE_ID: "AUTH_TOKEN"
};

export default constants;
