import { combineReducers } from "redux";
import auth from "./authReducer";

/**
 * Redux Root Reducer
 */

const rootReducer = combineReducers({
  auth
});

export default rootReducer;
