import React from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Axios from "axios";
import useAuth from "../hooks/useAuth";
import { apiUrl } from "../config";

export default function DeleteFile({ open, handleClose, file }) {
  const { authState } = useAuth();

  const handleDelete = async () => {
    Axios.post(
      apiUrl + "/file/delete/" + file.fileId,
      {},
      {
        headers: {
          Authorization: "Bearer " + authState.authToken
        }
      }
    )
      .then(response => {
        handleClose();
      })
      .catch(function(error) {
        console.log(error);
      });
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Delete: {file.name}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this file?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleDelete} color="primary">
            Yes
          </Button>
          <Button variant="contained" color="default" onClick={handleClose}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
