import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  root: {
    position: "absolute",
    bottom: 0,
    width: "100%",
    padding: theme.spacing(2)
  }
}));

export default function Footer() {
  const classes = useStyles();
  return (
    <Box className={classes.root} display="flex" justifyContent="center">
      <Typography>FileShare.io ©</Typography>
    </Box>
  );
}
