import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Axios from "axios";
import useAuth from "../hooks/useAuth";
import { apiUrl } from "../config";

export default function EditFile({ open, handleClose, file }) {
  const { authState } = useAuth();
  const [fileDescription, setFileDescription] = useState(file.description);

  const handleUpdate = async () => {
    Axios.post(
      apiUrl + "/file/update",
      { ...file, description: fileDescription },
      {
        headers: {
          Authorization: "Bearer " + authState.authToken,
          "Content-Type": "application/json",
          Accept: "application/json"
        }
      }
    )
      .then(response => {
        handleClose();
      })
      .catch(function(error) {
        console.log(error);
      });
  };

  useEffect(() => {
    setFileDescription(file.description);
  }, [file]);

  const handleDescriptionChange = e => {
    setFileDescription(e.target.value);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Edit: {file.name}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <TextField
              label="Description"
              margin="normal"
              onChange={handleDescriptionChange}
              value={fileDescription}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleUpdate} color="primary">
            Save
          </Button>
          <Button variant="contained" color="default" onClick={handleClose}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
