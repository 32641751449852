import React, { Component, useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  DialogContentText,
  Typography,
  Box
} from "@material-ui/core";
import { Formik } from "formik";
import * as Yup from "yup";
import Axios from "axios";
import useAuth from "../hooks/useAuth";
import Avatar from "@material-ui/core/Avatar";
import CheckIcon from "@material-ui/icons/Check";
import { apiUrl } from "../config";
import { GoogleLogin } from "react-google-login";

/**
 * Login dialog component
 */

const useStyles = makeStyles(theme => ({
  root: {}
}));

export default function Login({ open, toggle }) {
  const classes = useStyles();
  const [serverErrors, setServerErrors] = useState([]);
  const [success, setSuccess] = useState(false);
  const { setAuth, authState } = useAuth();

  useEffect(() => {
    if (open) {
      setSuccess(false);
      setServerErrors([]);
    }
  }, [open]);

  const responseGoogle = response => {
    if (response.code) {
      setServerErrors([]);

      //console.log(response);

      //sign in with google code on back-end
      Axios.post(apiUrl + "/user/googlelogin", { code: response.code })
        .then(response => {
          //set token in local storage
          setAuth(response.data.token);
          setSuccess(true);
        })
        .catch(error => {
          if (error.response) setServerErrors(error.response.data.errors);
        });
    }
  };

  return (
    <div className={classes.root}>
      <Dialog
        onClose={() => toggle(false)}
        aria-labelledby="simple-dialog-title"
        open={open}
        fullWidth
        PaperProps={{ style: { maxWidth: 400 } }}
      >
        {!success && (
          <>
            <DialogTitle id="simple-dialog-title">Sign in</DialogTitle>
            <Formik
              initialValues={{
                email: "",
                password: ""
              }}
              onSubmit={(values, { setSubmitting }) => {
                setServerErrors([]);

                Axios.post(apiUrl + "/user/login", values)
                  .then(response => {
                    //login success

                    //set token in local storage
                    setAuth(response.data.token);

                    setSubmitting(false);
                    setSuccess(true);
                  })
                  .catch(error => {
                    setSubmitting(false);
                    setServerErrors(error.response.data.errors);
                  });
              }}
              validationSchema={Yup.object().shape({
                email: Yup.string()
                  .email()
                  .required(),
                password: Yup.string().required()
              })}
            >
              {props => {
                const {
                  values,
                  touched,
                  errors,
                  dirty,
                  isSubmitting,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  handleReset
                } = props;
                return (
                  <form onSubmit={handleSubmit}>
                    <DialogContent>
                      <DialogContentText color="error">
                        {serverErrors.map((error, index) => (
                          <Typography key={index} component="span">
                            {error}
                          </Typography>
                        ))}
                      </DialogContentText>
                      <TextField
                        margin="dense"
                        id="email"
                        label="Email *"
                        fullWidth
                        value={values.email}
                        onChange={handleChange}
                        error={!!(touched.email && errors.email)}
                        helperText={touched.email && errors.email}
                      />
                      <TextField
                        margin="dense"
                        id="password"
                        label="Password *"
                        type="password"
                        fullWidth
                        value={values.password}
                        onChange={handleChange}
                        error={!!(touched.password && errors.password)}
                        helperText={touched.password && errors.password}
                      />
                      <Box marginTop={2} display="flex" justifyContent="center">
                        <GoogleLogin
                          clientId="661476601954-k5a05jlph114jb04o4v9andicgpqrvqi.apps.googleusercontent.com"
                          buttonText="Login with Google"
                          onSuccess={responseGoogle}
                          onFailure={responseGoogle}
                          responseType="code"
                          redirectUri="http://localhost:3000"
                          theme="dark"
                        />
                      </Box>
                    </DialogContent>

                    <DialogActions>
                      <Button onClick={() => toggle(false)}>Cancel</Button>
                      <Button
                        type="submit"
                        disabled={isSubmitting}
                        variant="outlined"
                      >
                        Login
                      </Button>
                    </DialogActions>
                  </form>
                );
              }}
            </Formik>
          </>
        )}
        {success && (
          <>
            <DialogTitle align="center">Successfully Signed in</DialogTitle>
            <DialogContent align="center">
              <Avatar style={{ color: "white", backgroundColor: "#00cc00" }}>
                <CheckIcon fontSize="large" />
              </Avatar>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => toggle(false)} variant="outlined">
                Close
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    </div>
  );
}
