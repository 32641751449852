import React, { useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Routes from "./Routes";
import NavBar from "../components/Navbar";
import { makeStyles } from "@material-ui/core/styles";
import useAuth from "../hooks/useAuth";
import Footer from "../components/Footer";

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: "100vh",
    position: "relative"
  }
}));

export default function Template() {
  const classes = useStyles();
  const { getAuthFromStorage } = useAuth();

  //check if token is in local storage
  useEffect(() => {
    getAuthFromStorage();

    return () => {};
  }, []);

  return (
    <Router>
      <div className={classes.root}>
        <NavBar />
        <Routes />
        <Footer />
      </div>
    </Router>
  );
}
