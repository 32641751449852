import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { Button, Menu, MenuItem, Avatar } from "@material-ui/core";
import Register from "./Register";
import Login from "./Login";
import { Link } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import CloudUpload from "@material-ui/icons/CloudUpload";
import ArrowDropDown from "@material-ui/icons/ArrowDropDown";
import Folder from "@material-ui/icons/Folder";
import AccountCircle from "@material-ui/icons/AccountCircle";

/**
 * Navbar Component
 */

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  grow: {
    flexGrow: 1
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1
  },

  button: {
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5)
  },

  avatar: {
    marginLeft: theme.spacing(2),
    cursor: "pointer",
    display: "flex",
    alignItems: "center"
  }
}));

export default function NavBar() {
  const classes = useStyles();

  const [registerOpen, setRegisterOpen] = useState(false);
  const [loginOpen, setLoginOpen] = useState(false);
  const { authState, logout } = useAuth();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const isMenuOpen = Boolean(anchorEl);

  function handleMenuClose() {
    setAnchorEl(null);
  }
  function handleProfileMenuOpen(event) {
    setAnchorEl(event.currentTarget);
  }

  return (
    <div className={classes.root}>
      <AppBar position="fixed" color="default" className={classes.appBar}>
        <Toolbar>
          <Link
            to="/"
            className={classes.grow}
            style={{
              textDecoration: "none",
              color: "white",
              display: "flex",
              alignItems: "center"
            }}
          >
            <img
              src="https://gcucapstone.s3-us-west-1.amazonaws.com/logo.png"
              style={{ height: 55 }}
            />
            <Typography variant="h5">FileShare.io</Typography>
          </Link>

          {!authState.authenticated && (
            <>
              <Button
                size="large"
                className={classes.button}
                onClick={() => setRegisterOpen(true)}
              >
                Sign up
              </Button>
              <Button
                size="large"
                className={classes.button}
                onClick={() => setLoginOpen(true)}
              >
                Login
              </Button>
              <Button
                size="large"
                variant="outlined"
                className={classes.button}
                component={Link}
                to="/upload"
              >
                <CloudUpload style={{ marginRight: 4 }} />
                Upload
              </Button>
            </>
          )}

          {authState.authenticated && (
            <>
              <Button
                size="large"
                className={classes.button}
                variant="outlined"
                component={Link}
                to="files"
              >
                <Folder style={{ marginRight: 4 }} />
                My Files
              </Button>
              <Button
                size="large"
                variant="outlined"
                className={classes.button}
                component={Link}
                to="/upload"
              >
                <CloudUpload style={{ marginRight: 4 }} />
                Upload
              </Button>
              <div className={classes.avatar} onClick={handleProfileMenuOpen}>
                <Avatar
                  src={authState.identity.avatar && authState.identity.avatar}
                >
                  {!authState.identity.avatar && (
                    <span style={{ color: "white" }}>
                      {authState.identity.sub.substring(0, 1).toUpperCase()}
                    </span>
                  )}
                </Avatar>
                <ArrowDropDown />
              </div>
            </>
          )}
        </Toolbar>
      </AppBar>

      <Register
        open={registerOpen}
        toggle={setRegisterOpen}
        login={() => {
          setRegisterOpen(false);
          setLoginOpen(true);
        }}
      />
      <Login open={loginOpen} toggle={setLoginOpen} />

      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        keepMounted
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        open={isMenuOpen}
        onClose={handleMenuClose}
        getContentAnchorEl={null}
      >
        <MenuItem onClick={handleMenuClose}>Settings</MenuItem>
        <MenuItem
          onClick={() => {
            handleMenuClose();
            logout();
          }}
        >
          Logout
        </MenuItem>
      </Menu>
    </div>
  );
}
