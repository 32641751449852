import React, { useState, createRef } from "react";
import { makeStyles, fade } from "@material-ui/core/styles";
import {
  Box,
  Typography,
  Paper,
  ButtonBase,
  Button,
  CircularProgress,
  TextField
} from "@material-ui/core";
import Dropzone from "react-dropzone";
import Axios from "axios";
import { apiUrl } from "../config";
import useAuth from "../hooks/useAuth";

/**
 * Upload page component
 */

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  },
  dropZonePaper: {
    width: "100%",
    padding: theme.spacing(2),
    backgroundColor: fade(theme.palette.common.white, 0.1),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25)
    },
    boxShadow:
      "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);",
    transition:
      "background-color 500ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 500ms cubic-bezier(0.4, 0, 0.2, 1)",
    transform: "scale(1, 1)",

    cursor: "pointer",
    borderRadius: "4px",
    border: "dashed",
    borderColor: "gray",
    height: 200,

    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  flexItem: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  }
}));

export default function Upload() {
  const classes = useStyles();

  const dropzoneRef = createRef();
  const [selectedFile, setSelectedFile] = useState({ name: "None" });
  const [uploadProgress, setUploadProgress] = useState(0);
  const [fileId, setFileId] = useState(0);
  const { authState } = useAuth();
  const [fileDescription, setFileDescription] = useState("");
  const [success, setSuccess] = useState(false);

  function onDrop(acceptedFiles) {
    setSelectedFile(acceptedFiles[0]);
    //console.log("files: ", acceptedFiles);
  }

  const handleDescriptionChange = e => {
    setFileDescription(e.target.value);
  };

  const reset = () => {
    setSelectedFile({ name: "none" });
    setUploadProgress(0);
    setFileId(0);
    setFileDescription("");
    setSuccess(false);
  };

  function uploadFile() {
    let formData = new FormData();
    formData.append("file", selectedFile);
    formData.append("description", fileDescription);
    let headers = {
      "Content-Type": "multipart/form-data"
    };
    if (authState.authenticated) {
      headers["Authorization"] = "Bearer " + authState.authToken;
    }

    Axios.post(apiUrl + "/file/upload", formData, {
      headers: headers,
      onUploadProgress: progress => {
        setUploadProgress(Math.round((progress.loaded * 100) / progress.total));
      }
    })
      .then(response => {
        //this.setState({ stage: "uploaded", fileId: response.data });
        setFileId(response.data);
        setSuccess(true);
      })
      .catch(function(error) {
        console.log(error);
      });
  }

  return (
    <div className={classes.root}>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        {!success && (
          <>
            <Dropzone ref={dropzoneRef} onDrop={onDrop} multiple={false}>
              {({ getRootProps, getInputProps }) => (
                <ButtonBase
                  {...getRootProps()}
                  className={classes.dropZonePaper}
                >
                  <input {...getInputProps()} />
                  <Typography>
                    Drag 'n' drop a file here, or click to select a file (10MB
                    max)
                  </Typography>
                </ButtonBase>
              )}
            </Dropzone>

            <div className={classes.flexItem}>
              <Typography align="center">
                <b>Selected File: </b>
                {selectedFile.name}
              </Typography>
              <Typography align="center">
                {selectedFile.size && (
                  <>
                    <b>Size: </b>
                    {parseFloat(selectedFile.size / 1024 / 1024).toFixed(2)} MB
                  </>
                )}
              </Typography>
            </div>
            {selectedFile.size && (
              <div className={classes.flexItem}>
                <TextField
                  label="Description"
                  className={classes.textField}
                  margin="normal"
                  onChange={handleDescriptionChange}
                  value={fileDescription}
                />
              </div>
            )}
          </>
        )}

        <div className={classes.flexItem}>
          {uploadProgress === 0 && (
            <Button
              variant="contained"
              color="primary"
              disabled={!selectedFile.size}
              onClick={uploadFile}
            >
              Upload
            </Button>
          )}
          {!success && uploadProgress > 0 && (
            <div
              style={{
                position: "relative",
                display: "flex",
                justifyContent: "center",
                marginBottom: 8
              }}
            >
              <CircularProgress
                variant="static"
                size={55}
                value={uploadProgress}
              />
              <Typography style={{ position: "absolute", top: 15 }}>
                {uploadProgress}%
              </Typography>
            </div>
          )}
          {success && fileId !== 0 && (
            <Box display="flex" flexDirection="column" alignItems="center">
              <Typography className={classes.flexItem}>
                Success! Your file can now be downloaded from this link:{" "}
                <a href={"/download?id=" + fileId}>
                  {"/download?id=" + fileId}
                </a>
              </Typography>
              <Button
                variant="contained"
                color="primary"
                className={classes.flexItem}
                onClick={reset}
              >
                Upload Another File
              </Button>
            </Box>
          )}
        </div>
      </Box>
    </div>
  );
}
